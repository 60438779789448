import SiteWrapper from "../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../components/mdrender";
import * as md from "../components/mdrender.module.sass";
import TitleBlock from "../components/title";

import "./product@gen.scss";

const GeneratedProduct = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>PagerDuty製品情報｜PagerDuty正規代理店 DXable</title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="PagerDutyはアラートを確実に配信、アップタイムを死守するインシデント管理ツールです。ダウンタイムやシステムの不正確さから企業を保護し、他の監視ツールとの統合も可能です。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="PagerDutyはアラートを確実に配信、アップタイムを死守するインシデント管理ツールです。ダウンタイムやシステムの不正確さから企業を保護し、他の監視ツールとの統合も可能です。"
        />

        <meta
          property="og:title"
          content="PagerDuty製品情報｜PagerDuty正規代理店 DXable"
        />

        <meta
          property="og:description"
          content="PagerDutyはアラートを確実に配信、アップタイムを死守するインシデント管理ツールです。ダウンタイムやシステムの不正確さから企業を保護し、他の監視ツールとの統合も可能です。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="製品"
        overview=""
      />

      <div className="productWrapper">
        <div className="product-container">
          <div className="center-1">
            <h2 className="center-title">PagerDutyとは？</h2>
            <p className="center-para">
              PagerDuty（ページャーデューティー）は、アラートを確実に配信し、稼働を維持するためのインシデント管理ツールです。監視ツールやチケットシステム、ヘルプデスク、CRM、マーケティングオートメーションなど650以上のツールやプラットフォームと連携できます。
              <br />
              さまざまな監視ツールからインシデントに関するデータを集約して、大事な警告とアラームに優先順位を付けて、インシデント対応の担当者にメール・SMS・音声メッセージなどでアラートを送信します。
              <br />
              さらに担当者が受任したことの確認や、エスカレーション、自動再ルーティングなどの多彩な機能により確実に担当者に配信されます。
              対応者はモバイルアプリからもインシデントの確認と対応が可能です。
            </p>
          </div>
          <div className="center-1">
            <div className="second-section">
              <div className="left-col">
                <div className="text-area">
                  <p>必要な情報を集約してインシデントを通知</p>
                </div>
                <div className="divider" />
              </div>
              <div className="right-col">
                PagerDutyの機械学習アルゴリズムは、自動的にノイズを減らしてコンテキストと推奨事項を作成するため、重要なイベントに集中できるようになります。
              </div>
            </div>
          </div>
          <div className="center-1">
            <div className="third-section">
              <div className="left-col">
                <details open>
                  <summary>デジタル運用をプロアクティブに管理</summary>
                  <p>
                    PagerDutyを使えば、サービスごとに担当チームを柔軟に編成できるようになります。そして現在のサービスの正常性、待機中の担当者、そのサービスに関するインシデントなどの重要な情報を関係者が確実に把握できます。
                  </p>
                </details>
                <hr />
                <details>
                  <summary>デジタルデータからインサイトを集約</summary>
                  <p>
                    PagerDutyは650以上のサードパーティのツールとのインテグレーションが可能です。例えばAWSやGCP、Azureの監視ツールと連携してインシデントをトリガーでき、Slackなどで通知したり、Salesforce
                    Service
                    Cloudなどのカスタマーサービス機能と情報を共有できます。データを集約してリアルタイムのインサイトとアクションに変換します。
                  </p>
                </details>
                <hr />
                <details>
                  <summary>適切なエキスパート／チームをアサイン</summary>
                  <p>
                    重要な問題を迅速に解決できるエキスパートチーム全体の作業を動的に自動化します。プロアクティブな通知とステータスダッシュボードを通じて、ステークホルダーへの適切なビジネス対応をリアルタイムで推進します。
                  </p>
                </details>
                <hr />
                <details>
                  <summary>機械学習とランブックによる自動化・標準化</summary>
                  <p>
                    PagerDuty独自の人間とマシンのデータの組み合わせと、統合された自動化により、チームのパフォーマンスが向上します。
                  </p>
                </details>
                <hr />
                <details>
                  <summary>インシデント対応にとどまらない機能</summary>
                  <p>
                    PagerDutyはオンコール管理とインシデント対応のツールですが、さらに多くの機能を備えています。チームのパフォーマンスや運用の健全性などに関する貴重なインサイトを得るのに役立ち、運用の継続的な改善にも貢献します。インシデントのビジネスへの影響を可視化し、ステークホルダーにリアルタイムで情報を提供します。ビジネス対応をサポートします。
                  </p>
                </details>
                <hr />
              </div>
              <div className="right-col">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/harness-signal-digital-data.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1676408828929&tr=q-100,f-gif"
                  alt="gif"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="center-1 center-2">
            <div className="fourth-section">
              <h3 className="title">クラス最高の製品</h3>
              <div className="main-layout">
                <div className="single">
                  <img
                    src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/DevOps.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1675852156644&tr=q-100,f-webp"
                    alt="DevOps"
                    loading="lazy"
                    className="icon"
                  />
                  <h4 className="single-title">DevOps</h4>
                  <p className="single-text">
                    オンコール管理と自動インシデント対応で信頼性を向上
                  </p>
                </div>
                <div className="single">
                  <img
                    src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/AIOps.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1675852156789&tr=q-100,f-webp"
                    alt="AIOps"
                    loading="lazy"
                    className="icon"
                  />
                  <h4 className="single-title">AIOps</h4>
                  <p className="single-text">
                    機械学習、イベントオーケストレーション、自動化による運用の改善
                  </p>
                </div>
                <div className="single">
                  <img
                    src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/ProcessAutomation.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1675852156813&tr=q-100,f-webp"
                    alt="プロセスの自動化"
                    loading="lazy"
                    className="icon"
                  />
                  <h4 className="single-title">自動化</h4>
                  <p className="single-text">
                    ビジネスおよびITプロセスのオーケストレーションされた自動化により、マシンの速度で運用
                  </p>
                  <a href="https://pagerduty.digitalstacks.net/automation/ ">
                    <span>→</span>
                    プロセスの自動化
                  </a>
                </div>
                <div className="single">
                  <img
                    src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/BusinessOperation.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1675852155801&tr=q-100,f-webp"
                    alt="事業運営"
                    loading="lazy"
                    className="icon"
                  />
                  <h4 className="single-title">ビジネスの継続的改善</h4>
                  <p className="single-text">
                    カスタマーサービスチームと機能チームを連携させ、オペレーショナルエクセレンスを推進
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="center-1">
            <div className="fifth-section">
              <h3 className="title">製品紹介コンテンツ</h3>
              <div className="main-layout">
                <div className="single">
                  <a href="https://pagerduty.digitalstacks.net/product/features/">
                    <h4 className="title">特徴</h4>
                  </a>
                  <p className="content">
                    PagerDutyのアラート、スケジューリング、エスカレーション、インシデント管理などの多くの機能を解説します
                  </p>
                </div>
                <div className="single">
                  <a href="https://pagerduty.digitalstacks.net/product/applications/ ">
                    <h4 className="title">アプリケーション</h4>
                  </a>
                  <p className="content">
                    PagerDutyの強力な拡張機能、オペレーションコマンドコンソールについて説明します
                  </p>
                </div>
                <div className="single">
                  <a href="https://pagerduty.digitalstacks.net/product/use-cases/">
                    <h4 className="title">ユースケース</h4>
                  </a>
                  <p className="content">
                    DevOps、IT運用、セキュリティー運用、サポートなど、担当部門ごとにPagerDutyの使い方を紹介します
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProduct;
